import React, { FunctionComponent, HTMLAttributes } from 'react';
import { generateDimensionAttributes } from '../../../helpers/images/images.helper';
import { BaseImage, CustomStyleOptions, ObjectFit } from '../base-image/base-image.component';

type ExternalImageOptions = {
	height?: number;
	width?: number;
	objectFit?: ObjectFit;
};

export type ExternalImageProps = {
	actualImageProps?: Partial<Omit<HTMLAttributes<HTMLImageElement>, 'alt' | 'className'>>;
	className?: string;
	bypassLazyLoad?: boolean;
	description?: string;
	ignoreChromatic?: boolean;
	limitHeight?: boolean;
	lowQualityPlaceholder?: string;
	options?: ExternalImageOptions;
	source: string;
	useDimensions?: boolean; // determines if width and height attributes will be applied to the img tag, can be passed false to disable this behavior
};

export const ExternalImage: FunctionComponent<ExternalImageProps> = ({
	bypassLazyLoad,
	className,
	description,
	ignoreChromatic,
	actualImageProps = {},
	limitHeight = false,
	lowQualityPlaceholder,
	options,
	source,
	useDimensions = true
}) => {
	const { width = 0, height = 0, objectFit } = options || {};
	const dimensions = generateDimensionAttributes(width, height);
	let customStyles: CustomStyleOptions = {};
	if (objectFit) {
		customStyles = { ...customStyles, objectFit };
	}

	return (
		<BaseImage
			actualImageProps={actualImageProps}
			bypassLazyLoad={bypassLazyLoad}
			className={className}
			customStyles={customStyles}
			description={description}
			dimensions={dimensions}
			ignoreChromatic={ignoreChromatic}
			limitHeight={limitHeight}
			lowQualityPlaceholder={lowQualityPlaceholder}
			shouldUseDimensions={useDimensions}
			source={source}
		/>
	);
};
