import React, { FunctionComponent } from 'react';
import { MaybeLink } from '../../common-components/link/link.component';
import { ExternalImage } from '../../images/external-image/external-image.component';

export type ShowroomMerchandisingBannerProps = {
	imageUrl: string;
	title: string;
	linkUrl?: string | null;
};

export const ShowroomMerchandisingBanner: FunctionComponent<ShowroomMerchandisingBannerProps> = ({ title, imageUrl, linkUrl }) => (
	<div className="w-100 mb4">
		<MaybeLink url={linkUrl} openInNewTab={true}>
			<ExternalImage source={imageUrl} description={title} className="w-100" />
		</MaybeLink>
	</div>
);
