import React, { FunctionComponent, HTMLAttributes, useEffect } from 'react';
import { PartialPick } from '../../../../../@types/build';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { useExpandableBehavior } from '../../../hooks/expandable/expandable.hooks';
import { ChevronDownIcon, ChevronUpIcon, RemoveIcon, AddIcon } from '../../svg/icons.component';
import { Badge } from '../badge/badge.component';

type IndicatorPositions = 'left' | 'right';
type IndicatorStyles = 'plus' | 'chevron';

export type ExpandablePanelProps = {
	children: JSX.Element | JSX.Element[];
	containerClassName?: string;
	heading?: string | JSX.Element;
	forceExpand?: boolean;
	startExpanded?: boolean;
	onExpand?: Function;
	onCollapse?: Function;
	renderCollapsedChildren?: boolean;
	borderRadius?: string;
	badgeCount?: number;
	automationHook?: string;
	indicatorPosition?: IndicatorPositions;
	indicatorStyle?: IndicatorStyles;
	forceClose?: boolean;
	expandedBorder?: boolean;
	disabled?: boolean;
	lightBorder?: boolean;
	headerClassName?: string;
} & PartialPick<HTMLAttributes<HTMLElement>, 'id' | 'className'>;

const EXPAND_INDICATORS_MAP: Readonly<{ [key in IndicatorStyles]: React.ReactNode }> = {
	plus: <AddIcon />,
	chevron: <ChevronDownIcon />
};

const COLLAPSE_INDICATORS_MAP: Readonly<{ [key in IndicatorStyles]: React.ReactNode }> = {
	plus: <RemoveIcon />,
	chevron: <ChevronUpIcon />
};

const Indicator: FunctionComponent<{ indicatorStyle: IndicatorStyles; isExpanded: boolean }> = ({ indicatorStyle, isExpanded }) => {
	const expandIcon = EXPAND_INDICATORS_MAP[indicatorStyle];
	const collapseIcon = COLLAPSE_INDICATORS_MAP[indicatorStyle];

	return (
		<div data-testid="indicator" className="f-inherit lh-solid ml3 flex items-center" data-automation="expand">
			{isExpanded ? collapseIcon : expandIcon}
		</div>
	);
};

export const ExpandablePanel: FunctionComponent<ExpandablePanelProps> = ({
	children,
	id,
	expandedBorder = true,
	className = 'f3',
	containerClassName = 'pa3',
	renderCollapsedChildren = false,
	heading,
	onExpand,
	onCollapse,
	borderRadius,
	badgeCount = 0,
	forceExpand = false,
	startExpanded = false,
	automationHook,
	indicatorPosition = 'left',
	indicatorStyle = 'plus',
	forceClose = false,
	disabled = false,
	lightBorder = false,
	headerClassName = ''
}) => {
	const { isExpanded, toggle, handleKeyPress, handleClick } = useExpandableBehavior({
		startExpanded,
		onExpand,
		onCollapse
	});

	const childrenDisplayClass = !isExpanded ? 'dn' : `db ${containerClassName}`;
	const shouldRenderChildren = isExpanded || renderCollapsedChildren;

	useEffect(() => {
		if (forceExpand && !isExpanded) {
			toggle();
		}
		if (forceClose && isExpanded) {
			toggle();
		}
	}, [forceExpand, forceClose, isExpanded, toggle]);

	return (
		<section
			id={id}
			className={`w-100 ba ${borderRadius ? borderRadius : 'br1'} ${
				lightBorder ? 'b--theme-grey-lighter' : 'b--theme-grey-light'
			} ${className}`}
			role="tabpanel"
			data-automation={generateDataSelector('panel', automationHook)}>
			<button
				className={`pointer flex ${
					indicatorPosition === 'right' ? 'justify-between mh2 pl0 pr3' : ''
				} items-center pv3 w-100 bn bg-transparent`}
				style={{ fontSize: 'inherit' }}
				onClick={handleClick}
				tabIndex={0}
				onKeyPress={handleKeyPress}
				aria-label={`Expand ${heading} Section`}
				aria-expanded={isExpanded}
				disabled={disabled}>
				{indicatorPosition === 'right' ? (
					<>
						<div className="flex items-center">
							<h2 className={`mv0 mh2 lh-title f-inherit ${headerClassName}`}>{heading}</h2>
							<Badge count={badgeCount} />
						</div>
						<div className="mr2">
							<Indicator isExpanded={isExpanded} indicatorStyle={indicatorStyle} />
						</div>
					</>
				) : (
					<>
						<Indicator isExpanded={isExpanded} indicatorStyle={indicatorStyle} />
						<h2 className={`mv0 mh2 lh-title f-inherit ${headerClassName}`}>{heading}</h2>
						<Badge count={badgeCount} />
					</>
				)}
			</button>
			{isExpanded && <div className={`mh3 ${expandedBorder ? 'bb b--theme-grey-light' : ''}`} />}
			<div className={childrenDisplayClass}>{shouldRenderChildren && children}</div>
		</section>
	);
};
