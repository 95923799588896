import React, { FunctionComponent, PropsWithChildren } from 'react';

// #region Props
export type TableCellProps = {
	className?: string;
	colSpan?: number;
};
export type TableHeadCellProps = {
	className?: string;
};
export type TableHeadProps = {
	className?: string;
};
export type TableBodyProps = {
	className?: string;
};
export type TableRowProps = {
	className?: string;
};
export type TableProps = {
	className?: string;
};
// #endregion

export const TableCell: FunctionComponent<PropsWithChildren<TableCellProps>> = ({ className = 'pa2', children, colSpan }) => (
	<td className={className} colSpan={colSpan}>
		{children}
	</td>
);

export const TableHeadCell: FunctionComponent<PropsWithChildren<TableHeadCellProps>> = ({ className = 'pa2 tl', children }) => (
	<th className={className}>{children}</th>
);

export const TableHead: FunctionComponent<PropsWithChildren<TableHeadProps>> = ({ className = 'bg-theme-grey-lighter', children }) => (
	<thead className={className}>{children}</thead>
);

export const TableBody: FunctionComponent<PropsWithChildren<TableBodyProps>> = ({ className, children }) => (
	<tbody className={className}>{children}</tbody>
);

export const TableRow: FunctionComponent<PropsWithChildren<TableRowProps>> = ({
	className = 'bt b--theme-grey-light',
	children,
	...remainingProps
}) => (
	<tr className={className} {...remainingProps}>
		{children}
	</tr>
);

export const Table: FunctionComponent<PropsWithChildren<TableProps>> = ({
	className = 'collapse w-100 f6 ba b--theme-grey-light',
	children
}) => <table className={className}>{children}</table>;
