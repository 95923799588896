import { ImageFieldsFragment, VideoFieldsFragment, ThreeSixtyImageFieldsFragment } from '../../__generated__/graphql-client-types';

export type CarouselItem = ImageFieldsFragment | VideoFieldsFragment | ThreeSixtyImageFieldsFragment;

export const handleCarouselMobileSwipe = (
	setTranslateValuePercentageCard: (translateValuePercentageCard: number) => void,
	setActiveIndex: (activeIndex: number) => void,
	setPrevTouchMoveValue: (touchMoveValue: number) => void,
	prevTouchMoveValue: number,
	touchStartXRef: number,
	touchMoveXRef: number,
	activeIndex: number,
	totalItems: number
) => {
	// Keeping track of prev touch move value, so carousel doesn't slide on click
	setPrevTouchMoveValue(touchMoveXRef);
	const touchMoveAmount = touchStartXRef - touchMoveXRef;
	if (Math.abs(touchMoveAmount) > 25 && touchMoveXRef !== prevTouchMoveValue) {
		// Direction of the swipe determines next or previous item
		const photoIndexChange = Math.sign(touchMoveAmount);
		// Clamp new index between 0 and last item index
		const newPhotoIndex = Math.max(0, Math.min(activeIndex + photoIndexChange, totalItems));
		setTranslateValuePercentageCard(newPhotoIndex * -100);
		setActiveIndex(newPhotoIndex);
	}
};

export const onRightButtonClick = (
	setTranslateValuePercentageCard: (translateValuePercentageCard: number) => void,
	setActiveIndex: (activeIndex: number) => void,
	activeIndex: number
) => {
	setActiveIndex(activeIndex + 1);
	setTranslateValuePercentageCard((activeIndex + 1) * -100);
};

export const onLeftButtonClick = (
	setTranslateValuePercentageCard: (translateValuePercentageCard: number) => void,
	setActiveIndex: (activeIndex: number) => void,
	activeIndex: number
) => {
	setActiveIndex(activeIndex - 1);
	setTranslateValuePercentageCard((activeIndex - 1) * -100);
};
