import React, { FunctionComponent, useRef, useState } from 'react';
import { ImageFieldsFragment } from '../../../__generated__/graphql-client-types';
import { handleCarouselMobileSwipe, onLeftButtonClick, onRightButtonClick } from '../../../helpers/carousel/carousel.helper';
import { CarouselButtonsWithCount } from '../../carousel-components/carousel-buttons-with-count/carousel-buttons-with-count.component';
import { CloudinaryImage } from '../../images/cloudinary-image/cloudinary-image.component';
import { carouselButtonsWithCountStyle, transitionStyle } from './showroom-image-carousel.css';

export type ShowroomImageCarouselCardProps = {
	image: ImageFieldsFragment;
	ariaLabel: string;
};

export const ShowroomImageCarouselCard: FunctionComponent<ShowroomImageCarouselCardProps> = ({ image, ariaLabel }) => {
	return (
		<CloudinaryImage
			className="w-100 w-auto-ns"
			publicID={image.id}
			options={{ crop: 'lpad', width: 749, height: 478, type: image.imageType }}
			placeholderOptions={{ crop: 'lpad', width: 749, height: 478, type: image.imageType }}
			description={ariaLabel}
			useDimensions={false}
		/>
	);
};

export type ShowroomImageCarouselProps = {
	items: ImageFieldsFragment[];
	ariaLabel: string;
};

export const ShowroomImageCarousel: FunctionComponent<ShowroomImageCarouselProps> = ({ items, ariaLabel }) => {
	// in case there's an image with no id
	const filteredImages = items.filter((item) => item.id);
	const [activeIndex, setActiveIndex] = useState(0);
	const [translateValuePercentageCard, setTranslateValuePercentageCard] = useState(0);
	const [prevTouchMoveValue, setPrevTouchMoveValue] = useState(0);

	// Mobile touch event refs
	const touchStartXRef = useRef<number>(0);
	const touchMoveXRef = useRef<number>(0);
	const sliderContainerRef = useRef<HTMLDivElement>(null);

	return (
		<>
			<div
				className="relative overflow-hidden"
				data-testid="showroom-image-carousel"
				style={{ boxShadow: '0 .125rem .25rem rgba(0,0,0,.15)' }}>
				<div
					className={`flex h-100 ${transitionStyle}`}
					data-testid="showroom-image-carousel-slider"
					ref={sliderContainerRef}
					onTouchStart={(e) => (touchStartXRef.current = e.targetTouches[0].clientX)}
					onTouchMove={(e) => (touchMoveXRef.current = e.targetTouches[0].clientX)}
					onTouchEnd={() =>
						handleCarouselMobileSwipe(
							setTranslateValuePercentageCard,
							setActiveIndex,
							setPrevTouchMoveValue,
							prevTouchMoveValue,
							touchStartXRef.current,
							touchMoveXRef.current,
							activeIndex,
							items.length - 1
						)
					}
					style={{
						transform: `translate(${translateValuePercentageCard}%)`,
						scrollBehavior: 'smooth',
						touchAction: 'pan-y'
					}}>
					{filteredImages.map((item, index) => (
						<div className="flex-shrink-0" style={{ flexBasis: '100%' }} key={`${item.id}-${index}`}>
							<ShowroomImageCarouselCard image={item} ariaLabel={`${ariaLabel} slide ${index}`} />
						</div>
					))}
				</div>
			</div>
			<CarouselButtonsWithCount
				onLeftButtonClick={() => onLeftButtonClick(setTranslateValuePercentageCard, setActiveIndex, activeIndex)}
				onRightButtonClick={() => onRightButtonClick(setTranslateValuePercentageCard, setActiveIndex, activeIndex)}
				activeIndex={activeIndex}
				totalItems={filteredImages.length}
				className={`bottom-2-ns pr2 theme-white relative justify-end z-2 ${carouselButtonsWithCountStyle}`}
			/>
		</>
	);
};
